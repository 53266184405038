import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Página no encontrada" />
    <div className="mc-wrapper-2 extra-padding">
      <div className="mc-wrapper-3 extra">
        <h1 className="title">Página no encontrada</h1>
        <p className="first">
          La página que has solicitado no existe, puede que te hayas equivocado
          o haya sido removida. Disculpa el inconveniente.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
